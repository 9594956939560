import React from "react"
import { FormattedMessage,injectIntl,Link } from "gatsby-plugin-intl"
import AirQ from "../../assets/img/wind.png"

 const AqiPopUp = ({ id,title, date, status, color, onClose, selectedRegion })=> {

  return (
    <div className="aqi-popup">
      <div className="aqi-popup__header">
        <button className="aqi-popup__close-button" onMouseUp={onClose}>
          X
        </button>
      </div>
      <div className="aqi-popup__body">
        <div className="top-info">
          <div
            className="aqi-popup__icon-wrapper"
            style={{ backgroundColor: `${color}` }}
          >
            <img src={AirQ} />
          </div>
          <div className="aqi-popup__title">{title}</div>
        </div>
        <div className="bottom-info">
          <div className="aqi-popup__date">
            <FormattedMessage id="last_update" />: {date}
          </div>
          <div className="aqi-popup__status">
            <FormattedMessage id="status_title"/> : <FormattedMessage id={status || "no_readings_available" }/>
          </div>
          <Link
            className="btn-aqi-popup"
            to={"/airQualityDetails/?country=" + selectedRegion.code +"&station_id=" + id}
          >
            <FormattedMessage id="more" />
          </Link>
        </div>
      </div>
    </div>
  )
}
export default injectIntl(AqiPopUp)
