import "../assets/css/air_quality.css"
import React from "react"
import { connect } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap"
import {
  FormattedMessage,
  injectIntl,
  navigate,
  Link,
} from "gatsby-plugin-intl"

import MapService, { createSaticEmptyMap } from "../utils/mapService"
import Collection from "ol/Collection"
import Feature from "ol/Feature"
import Layout from "../containers/Layout"
import SEO from "../components/seo"
import AqiPopup from "../components/airQuality/AqiPopup"
import axios from "../api/axios"
import * as actions from "../store/actions"
import DustChart from "../components/airQuality/dustChart"
import GasesChart from "../components/airQuality/gasesChart"
import ElementIndicator from "../components/airQuality/elementIndicator"
import * as chartColors from "../shared/chartColors"
import * as aqiRatings from "../shared/aqiStatus"
import ArabicLegendImg from "../images/airQuality/AirQualityLegend_ar.png"
import EnglishLegendImg from "../images/airQuality/AirQualityLegend_en.png"
import ArabicLegendMobile from "../images/airQuality/AirQualityDetailsLegend_ar.png"
import EnglishLegendMobile from "../images/airQuality/AirQualityDetailsLegend_en.png"
import { hexToRgb, markerStyle } from "../utils"
import BaseMapSwitcher from "../components/maps/BaseMapSwitcher"

class AirQualityPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
      stations: [],
      selectedStation: null,
      featureCollection: new Collection(),
      map: null,
      stationReading: {
        pmi: 0,
        pm25: 0,
        pm10: 0,
        aqi: 0,
        co: 0,
        o3: 0,
        no2: 0,
        so2: 0,
      },
      aqiPopupOpen: false,
    }
    this.readings = {}
    this.mapAqiInfoOverLayId = "map-aqi-info"
    this.mapOverLayId = "map-overlay"
  }
  createOverLay = _ => {
    // hover popup
    this.overLayElement = document.getElementById(this.mapOverLayId)
    this.markerOverLay = this.mapService.createOverLay(this.overLayElement)
    this.markerOverLay.setOffset([-20, -50])

    // aqi info popup
    this.aqiOverLayElement = document.getElementById(this.mapAqiInfoOverLayId)
    this.aqiOverLay = this.mapService.createOverLay(this.aqiOverLayElement, {
      className: this.mapAqiInfoOverLayId,
      autoPan: true,
    })
    this.aqiOverLay.setOffset([-150, -235])
  }
  onMapPointerMove = _ => {
    // construct callbacks here
    const onMarkerHover = event => {
      const features = event.map.getFeaturesAtPixel(event.pixel)
      const title = features && features[0].get("name")
      if (title) {
        this.overLayElement.innerHTML = title
        this.markerOverLay.setPosition(event.coordinate)
      } else {
        this.overLayElement.innerHTML = ""
        this.markerOverLay.setPosition(null)
      }
    }
    // include all callbacks on Pointer move
    this.mapService.onMapPointerMove([onMarkerHover])
  }
  onMapSingleClick = _ => {
    const onMapClick = event => {
      const { map } = event
      const features = map.getFeaturesAtPixel(event.pixel)
      const selectedFeature = features && features[0]
      if (selectedFeature) {
        const station = this.state.stations.find(
          s => s.id === selectedFeature.get("id")
        )
        this.setState({
          aqiPopupOpen: true,
        })
        this.stationChanged(station)
      }
    }
    this.mapService.onMapSingleClick([onMapClick])
  }
  componentDidMount = async () => {
    const { selectedRegion } = this.props
    const map = createSaticEmptyMap()
    const { featureCollection } = this.state
    this.mapService = new MapService(map)
    this.createOverLay()
    this.onMapPointerMove()
    this.onMapSingleClick()
    this.readings = await this.fetchAllReadings(this.props.intl.locale)
    if (selectedRegion) {
      this.fetchStations(this.props.intl.locale, selectedRegion)
      this.mapService.zoomToLocation(selectedRegion.location)
    }
    this.mapService.createVectorLayerFromCollection(featureCollection)
    map.setTarget(document.getElementById("map-div"))
    this.setState({ map: map })
  }

  componentWillReceiveProps(nextProps) {
    const { selectedRegion } = this.props
    if (
      nextProps.selectedRegion !== selectedRegion &&
      nextProps.selectedRegion
    ) {
      this.fetchStations(this.props.intl.locale, nextProps.selectedRegion)
      this.resetFeatureCollection()
      this.mapService.zoomToLocation(nextProps.selectedRegion.location)
      this.setState({
        stationReading: {
          pmi: 0,
          pm25: 0,
          pm10: 0,
          aqi: 0,
          co: 0,
          o3: 0,
          no2: 0,
          so2: 0,
        },
      })
    }
  }

  addStyleToFeature = () => {
    const { features } = this.state
    // add styles to features
    const markerStyle = this.createStationFeatureStyle()
    const styledFeatures = features.map(f => {
      f.setStyle(markerStyle)
      return f
    })
    // clear the old feature collections
    let { featureCollection } = this.state
    this.resetFeatureCollection()
    // add features to the map
    if (styledFeatures && styledFeatures.length > 0) {
      featureCollection.extend(styledFeatures)
    }
  }

  resetFeatureCollection = () => {
    let { featureCollection } = this.state
    featureCollection.clear()
  }

  showAqiDefinitionText = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  getChartColorByValue = value => {
    var color =
      value === 0
        ? chartColors.BLACK
        : value <= 50
        ? chartColors.GREEN
        : value <= 100
        ? chartColors.YELLOW
        : value <= 150
        ? chartColors.ORANGE
        : value <= 200
        ? chartColors.RED
        : value <= 300
        ? chartColors.PURPLE
        : value <= 500
        ? chartColors.BROWN
        : ""
    return color
  }

  getAqiStatus = value => {
    const aqiStatus = {
      [chartColors.BROWN]: aqiRatings.HAZARDOUS,
      [chartColors.PURPLE]: aqiRatings.VERY_UNHEALTHY,
      [chartColors.RED]: aqiRatings.UNHEALTHY,
      [chartColors.ORANGE]: aqiRatings.UNHEALTHY_SENSITIVE_GROUPS,
      [chartColors.YELLOW]: aqiRatings.GOOD,
      [chartColors.GREEN]: aqiRatings.VERY_GOOD,
      [chartColors.BLACK]: aqiRatings.READING_UNAVAILABLE,
    }[this.getChartColorByValue(value || 0)]
    return aqiStatus
  }

  createStationFeatureStyle = () => {
    const { stationReading, selectedStation } = this.state
    const { aqi } = stationReading
    const hexColor = this.getChartColorByValue(aqi)
    const rgb = hexToRgb(hexColor)
    return markerStyle(rgb.asString(), selectedStation.name)
  }

  fetchAllReadings = async locale => {
    let today = new Date()
    let dd = today.getDate()
    let mm = today.getMonth() + 1
    let yesterday = new Date(today)
    yesterday.setDate(today.getDate() - 1)
    let sFormat = `${yesterday.getFullYear()}-${yesterday.getMonth() +
      1}-${yesterday.getDate()}`
    let eFormat = `${today.getFullYear()}-${mm}-${dd}`
    const { data } = await axios(
      locale,
      `stations/readings/?start_date=${sFormat}&end_date=${eFormat}`
    )
    // return object instead of array
    return data.reduce((acc, obj) => {
      if (acc[obj["station"]]) return acc
      acc[obj["station"]] = obj
      return acc
    }, {})
  }

  fetchStations = async (locale, region) => {
    const regionParamPart =
      region.code === "GCC"
        ? "?is_default=true"
        : "?is_default=true&region=" + region.id
    const defaultReading = {
      pmi: 0,
      pm25: 0,
      pm10: 0,
      aqi: 0,
      co: 0,
      o3: 0,
      no2: 0,
      so2: 0,
    }
    const { data } = await axios(
      locale,
      "monitoringstations/" + regionParamPart
    )
    const features = data.map(feature => ({
      type: "Feature",
      geometry: feature["location"],
      properties: {
        ...feature,
        ...(this.readings[feature["id"]] || defaultReading),
        markerColor: this.getChartColorByValue(
          (this.readings[feature["id"]] || {})["aqi"] || 0
        ),
      },
    }))
    // remove layer from map
    if (this.state.geoJSONLayer) {
      this.mapService.removeLayer(this.state.geoJSONLayer)
    }
    const geojsonObject = {
      type: "FeatureCollection",
      crs: {
        type: "name",
        properties: {
          name: "WGS84",
        },
      },
      features,
    }
    const geoJSONLayer = this.mapService.addJSONFeaturesLayer(geojsonObject)

    var selectedStation = data[0];
    if (this.props.selectedRegion.code == "GCC") {
      const response = await fetch(process.env.SERVER_URL + 'client_country')
      const responseJSON = await response.json();
      if (responseJSON !== null) {
        selectedStation = data.find(station => station.region == responseJSON)
      }
    }

    this.setState({ stations: data, geoJSONLayer }, () => {
      this.stationChanged(selectedStation);
      // if any station id is passed via url param select its station
      var stationIdParam = new URL(window.location.href).searchParams.get("station_id")
      if (stationIdParam && parseInt(stationIdParam) !== data[0].id) {
        var paramStation = data.filter(
          station => station.id === parseInt(stationIdParam)
        )
        if (paramStation.length !== 0) this.stationChanged(paramStation[0])
      }
    })
  }

  fetchStationReading = (locale, stationID) => {
    let today = new Date()
    let dd = today.getDate()
    let mm = today.getMonth() + 1
    let yesterday = new Date(today)
    yesterday.setDate(today.getDate() - 1)
    let sFormat = `${yesterday.getFullYear()}-${yesterday.getMonth() +
      1}-${yesterday.getDate()}`
    let eFormat = `${today.getFullYear()}-${mm}-${dd}`
    axios(
      locale,
      `stations/readings/?start_date=${sFormat}&end_date=${eFormat}&station=${stationID}`
    ).then(response => {
      const data = response.data
      if (Array.isArray(data) && data.length > 0) {
        this.setState({ stationReading: data[0] }, this.addStyleToFeature)
      } else {
        this.setState(
          {
            stationReading: {
              pmi: 0,
              pm25: 0,
              pm10: 0,
              aqi: 0,
              co: 0,
              o3: 0,
              no2: 0,
              so2: 0,
            },
          },
          this.addStyleToFeature
        )
      }
    })
  }

  stationChanged = station => {
    this.setState(
      {
        selectedStation: station,
        stationReading: {
          pmi: 0,
          pm25: 0,
          pm10: 0,
          aqi: 0,
          co: 0,
          o3: 0,
          no2: 0,
          so2: 0,
        },
      },
      () => {
        const { selectedStation, map } = this.state
        if (selectedStation) {
          const feature = new Feature({
            name: selectedStation.name,
            geometry: this.mapService.getLocationGeom(selectedStation.location),
            ...selectedStation,
          })
          // condition if the map component is clicked only
          if (this.state.aqiPopupOpen)
            this.aqiOverLay.setPosition(feature.getGeometry().getCoordinates())
          else {
            this.mapService.zoomToLocation(this.props.selectedRegion.location)
          }
          this.mapService.addZoomToExtentControl(
            this.props.selectedRegion.location
          )
          this.mapService.updateSize()
          this.setState({ features: [feature] }, () => {
            this.fetchStationReading(this.props.intl.locale, selectedStation.id)
          })
        }
      }
    )
    navigate(
      "/airQuality/?country=" +
        this.props.selectedRegion.code +
        "&station_id=" +
        station.id
    )
  }

  render() {
    const {
      stations,
      selectedStation,
      stationReading,
      aqiPopupOpen,
      map,
      dropdownOpen,
    } = this.state
    let baseMaps = []
    const baseURL = process.env.SERVER_URL
    const mapLayers = map && map.getLayers().getArray()
    const layers = []
    mapLayers &&
      mapLayers.forEach(l => {
        if (l.get("type") === "base") baseMaps = l.getLayers().getArray()
        else layers.push(l)
      })
    const isArabicLocale = this.props.intl.locale === "ar"
    const headingsStyle = {
      textAlign: isArabicLocale ? "right" : "left",
      fontWeight: "600",
    }

    return (
      <Layout>
        <SEO title="Air Quality" />
        <div className="main">
          <div className="section justify-content-center">
            <div
              className="mt-5 p-4 flex"
              style={{ backgroundColor: "#d3d3d36e" }}
            >
              <Container>
                <Row>
                  <Col md={6} sm={12}>
                    <div className="station__header">
                      <h4 className="mt-1">
                        <FormattedMessage id="gcc_aqi_label" />
                      </h4>
                    </div>
                    {this.state.selectedStation && (
                      <div className="station__header mt-2">
                        <h4 className="station__title mt-1 mr-0 ml-0">
                          <FormattedMessage id="station_name" />
                        </h4>
                        :
                        <h4 className="station__title mt-1">
                          {this.state.selectedStation.name}
                        </h4>
                      </div>
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
            <Container className="justify-content-center">
              <div className="aq_main">
                <Container>
                  <Row>
                    <Col md={10} xs={12}>
                      <div id="map-div" />
                      <div id={this.mapOverLayId}></div>
                      <div id={this.mapAqiInfoOverLayId}>
                        {selectedStation && (
                          <AqiPopup
                            id={selectedStation.id}
                            title={selectedStation.name}
                            status={this.getAqiStatus(stationReading.aqi)}
                            date={stationReading.reading_day}
                            color={this.getChartColorByValue(
                              stationReading.aqi
                            )}
                            onClose={() => {
                              this.setState({ aqiPopupOpen: false })
                              this.aqiOverLay.setPosition(undefined)
                            }}
                            selectedRegion={this.props.selectedRegion}
                          />
                        )}
                      </div>
                      {baseMaps.length > 0 && (
                        <BaseMapSwitcher
                          baseMaps={baseMaps}
                          rtl={isArabicLocale}
                        />
                      )}
                    </Col>

                    <Col md={2} xs={12} className="align-self-center">
                      <span className="text-secondary">
                        <FormattedMessage id="aqi_title" />
                      </span>
                      <img
                        className="chart-info-display map-legend-img"
                        src={
                          this.props.intl.locale === "ar"
                            ? ArabicLegendImg
                            : EnglishLegendImg
                        }
                        alt="legend"
                      />
                    </Col>
                    <Col xs={12}>
                      <img
                        className="chart-info-display map-legend-img-mobile"
                        src={
                          this.props.intl.locale === "ar"
                            ? ArabicLegendMobile
                            : EnglishLegendMobile
                        }
                        alt="legend"
                      />
                    </Col>
                    <Col md={10} xs={12}>
                      <div
                        className="about-aqi"
                        onClick={this.showAqiDefinitionText}
                      >
                        <h5 className="about-title">
                          <FormattedMessage id="about_aqi" />
                        </h5>
                        <i
                          className={`fa fa-chevron-circle-down ${
                            dropdownOpen ? "rotate-180" : ""
                          }`}
                          aria-hidden="true"
                        ></i>
                      </div>

                      <div
                        className={`${
                          dropdownOpen
                            ? "what-is-aqi show_aqi_text"
                            : "what-is-aqi"
                        }`}
                      >
                        <p>
                          <FormattedMessage id="gcc_aqi_label" />
                        </p>
                        <p>
                          <FormattedMessage id="what_gcc_aqi" />
                        </p>
                        <a
                          color="primary"
                          href={`${baseURL}media/air_quality/GCCAQI-Methodolgy and Calculation.pdf`}
                          target="_blank"
                        >
                          <FormattedMessage id="more" />
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Container>
                {selectedStation !== null ? (
                  <>
                    <div className="clear"></div>
                    <h3 style={{ color: "gray" }}>
                      <FormattedMessage id="aqi_title" /> - {selectedStation.name}
                    </h3>
                    <Row className="chart-desktop-view">
                      <Col className="chart-col" md={5}>
                        <Row className="justify-content-center">
                          <DustChart value={this.state.stationReading.pm10} />
                        </Row>
                        <Row className="justify-content-center">
                          {/* <ElementIndicator
                            sm={6}
                            xs={6}
                            value={this.state.stationReading.pm25}
                            elementName="PM2.5"
                          /> */}
                          <ElementIndicator
                            sm={6}
                            xs={6}
                            value={this.state.stationReading.pm10}
                            elementName="PM10"
                          />
                        </Row>
                      </Col>
                      <Col className="text-center" md={2}>
                        <img
                          className="chart-info-display"
                          src={
                            this.props.intl.locale === "ar"
                              ? ArabicLegendImg
                              : EnglishLegendImg
                          }
                          alt="legend"
                        />
                      </Col>
                      <Col className="chart-col" md={5}>
                        <Row className="justify-content-center">
                          <GasesChart value={this.state.stationReading.aqi} />
                        </Row>
                        <Row className="justify-content-center">
                          <ElementIndicator
                            sm={3}
                            value={this.state.stationReading.co}
                            elementName="CO"
                          />
                          <ElementIndicator
                            sm={3}
                            value={this.state.stationReading.no2}
                            elementName="NO2"
                          />
                          <ElementIndicator
                            sm={3}
                            value={this.state.stationReading.o3}
                            elementName="O3"
                          />
                          <ElementIndicator
                            sm={3}
                            value={this.state.stationReading.so2}
                            elementName="SO2"
                          />
                        </Row>
                      </Col>
                    </Row>

                    <Row className="chart-responsive-view">
                      <Col md={12} className="chart-col">
                        <Row>
                          <Col xs={8} className="text-center">
                            <Row>
                              <DustChart value={this.state.stationReading.pm10} />
                            </Row>
                            <Row className="justify-content-center">
                              {/* <ElementIndicator
                                value={this.state.stationReading.pm25}
                                elementName="PM2.5"
                              /> */}
                              <ElementIndicator
                                value={this.state.stationReading.pm10}
                                elementName="PM10"
                              />
                            </Row>
                          </Col>

                          <Col xs={4} className="text-center">
                            <img
                              className="chart-info-display"
                              src={
                                this.props.intl.locale === "ar"
                                  ? ArabicLegendImg
                                  : EnglishLegendImg
                              }
                              alt="legend"
                            />
                          </Col>
                        </Row>
                      </Col>

                      <div className="clear"></div>

                      <Col md={12} className="chart-col">
                        <Row>
                          <Col xs={8} className="text-center">
                            <Row>
                              <GasesChart
                                value={this.state.stationReading.aqi}
                              />
                            </Row>
                            <Row className="justify-content-center">
                              <ElementIndicator
                                value={this.state.stationReading.co}
                                elementName="CO"
                              />
                              <ElementIndicator
                                value={this.state.stationReading.no2}
                                elementName="NO2"
                              />
                              <ElementIndicator
                                value={this.state.stationReading.o3}
                                elementName="O3"
                              />
                              <ElementIndicator
                                value={this.state.stationReading.so2}
                                elementName="SO2"
                              />
                            </Row>
                          </Col>

                          <Col xs={4} className="text-center">
                            <img
                              className="chart-info-display"
                              src={
                                this.props.intl.locale === "ar"
                                  ? ArabicLegendImg
                                  : EnglishLegendImg
                              }
                              alt="legend"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <div className="clear"></div>
                    <p style={{ color: "gray" }}>
                      <FormattedMessage id="reading_avg_last_day" />
                    </p>
                    <Row
                      style={{ width: "90%" }}
                      className="justify-content-end"
                    >
                      <Link
                        className="read-more-btn"
                        to={"/airQualityDetails/?station_id=" + selectedStation.id}
                        color="primary"
                      >
                        <FormattedMessage id="more" />
                      </Link>
                    </Row>

                    <hr style={{ width: "-webkit-fill-available" }} />

                    <h3 style={{ fontWeight: "600" }}>
                      <FormattedMessage id="air_quality_title" />
                    </h3>
                    <hr
                      style={{
                        height: "3px",
                        width: "25vw",
                        background: "#2ac3ef",
                        marginBottom: "30px",
                      }}
                    />

                    <Row style={{ width: "100%" }}>
                      <Col sm={3} md={2}>
                        <p style={headingsStyle}>
                          <FormattedMessage id="creation_date" />
                        </p>
                      </Col>
                      <Col sm={9} md={10}>
                        <p
                          style={{
                            textAlign: isArabicLocale ? "right" : "left",
                          }}
                        >
                          {selectedStation.station_creation_date || (
                            <FormattedMessage id="no_data_found" />
                          )}
                        </p>
                      </Col>
                    </Row>

                    <Row style={{ width: "100%" }}>
                      <Col sm={3} md={2}>
                        <p style={headingsStyle}>
                          <FormattedMessage id="station_name" />:
                        </p>
                      </Col>
                      <Col sm={9} md={10}>
                        <p style={headingsStyle}>{selectedStation.name}</p>
                      </Col>
                    </Row>
                    <Row style={{ width: "100%" }}>
                      <Col sm={3} md={2}>
                        <p style={headingsStyle}>
                          <FormattedMessage id="station_type" />
                        </p>
                      </Col>
                      <Col sm={9} md={10}>
                        <p
                          style={{
                            textAlign: isArabicLocale ? "right" : "left",
                          }}
                        >
                          {selectedStation.is_movable ? (
                            <FormattedMessage id="movable" />
                          ) : (
                            <FormattedMessage id="not_movable" /> || (
                              <FormattedMessage id="no_data_found" />
                            )
                          )}
                        </p>
                      </Col>
                    </Row>

                    <Row style={{ width: "100%" }}>
                      <Col sm={3} md={2}>
                        <p style={headingsStyle}>
                          <FormattedMessage id="description" />
                        </p>
                      </Col>
                      <Col sm={9} md={10}>
                        <p
                          style={{
                            textAlign: isArabicLocale ? "right" : "left",
                          }}
                        >
                          {selectedStation.description || (
                            <FormattedMessage id="no_data_found" />
                          )}
                        </p>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Spinner />
                )}

                <div className="clear"></div>

                {selectedStation && selectedStation.image && (
                  <div className="station-image-container">
                    <h4>
                      <FormattedMessage id="station_image" />
                    </h4>
                    <hr
                      style={{
                        height: "3px",
                        width: "10vw",
                        background: "#2ac3ef",
                        marginBottom: "30px",
                      }}
                    />
                    <img
                      src={selectedStation.image}
                      alt={selectedStation.name}
                      className="img-thumbnail"
                    />
                  </div>
                )}
              </div>
            </Container>
          </div>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    regions: state.regions.regions,
    selectedRegion: state.regions.selectedRegion,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectedRegion: regionCode =>
      dispatch(actions.setSelectedRegion(regionCode)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AirQualityPage))
