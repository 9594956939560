import React from "react"
import { Button, Col, Row } from 'reactstrap'
import classnames from "classnames"

class AirElementValue extends React.Component {

    render() {
        var colorClassName = this.props.value === 0  || this.props.value === null ? "btn-black" :
            this.props.value <= 50 ? "btn-green" :
                this.props.value <= 100 ? "btn-yellow" :
                    this.props.value <= 150 ? "btn-orange" :
                        this.props.value <= 200 ? "btn-red" :
                            this.props.value <= 300 ? "btn-purple" :
                                this.props.value <= 500 ? "btn-brown" : "";

        return (
            <Col xs={this.props.xs} sm={this.props.sm}>
                <Row className="justify-content-center">
                    <Button className={classnames("btn-round", colorClassName)}
                        style={{
                            cursor: "default",
                            width: "fit-content",
                            height: 45,
                        }}>
                        {this.props.value ? this.props.value : 0}
                    </Button>
                </Row>
                <Row className="justify-content-center">
                    <p>{this.props.elementName}</p>
                </Row>
            </Col>
        );
    }
}

export default AirElementValue