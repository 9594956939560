import React from 'react';
import { injectIntl } from "gatsby-plugin-intl"
import ReactSpeedometer from "react-d3-speedometer"
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'reactstrap';

import * as chartColors from "../../shared/chartColors"


class GasesChart extends React.Component {

    render() {
        const isMobile = window.innerWidth < 460

        return (
            <Col>
                <Row className="justify-content-center">
                    <h5 className="m-3" style={{ fontWeight: "bold" }}><FormattedMessage id="gases" /></h5>
                </Row>
                <Row className="justify-content-center">
                    <ReactSpeedometer
                        needleHeightRatio={0.68}
                        maxSegmentLabels={6}
                        minValue={0}
                        maxValue={500}
                        width={isMobile ? 210 : 290}
                        height={isMobile ? 190 : 200}
                        needleTransitionDuration={2500}
                        needleTransition="easeElastic"
                        needleColor="gray"
                        ringWidth={50}
                        customSegmentStops={[
                            0,
                            50,
                            100,
                            150,
                            200,
                            300,
                            500]}
                        segmentColors={[
                            chartColors.GREEN,
                            chartColors.YELLOW,
                            chartColors.ORANGE,
                            chartColors.RED,
                            chartColors.PURPLE,
                            chartColors.BROWN]}
                        value={this.props.value} />
                </Row>
            </Col>
        );
    }
}

export default injectIntl(GasesChart)